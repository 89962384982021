import _ from 'underscore';
import React from 'react';

let MealTypeView = ({ meals }) => (
        <section>
        <h2>Meals</h2>
        <ul>
            {meals.map((mealType) => (
                <li key={mealType.cid}>
                    {mealType.get('name')}
                    <button onClick={(e) => meals.remove(mealType)}>&times;</button>
                </li>
            ))}
        </ul>
        <AddMeal meals={meals} />
        </section>
);

let AddMeal = ({ meals }) => {
    let input;
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            if (input.value.trim()) {
                meals.add({ name: input.value.trim() });
                input.value = "";
            }
        }}>
            <input placeholder="Breakfast" type="text" ref={(node) => input = node } />
            <input type="submit" value="Add" />
        </form>
    );
}

export default MealTypeView;
