import React from 'react';

let PeopleView = ({ people }) => (
    <section>
        <h2>People</h2>
        <ul>
            { people.map((person) => {
                return <li key={person.cid} style={{ borderLeft: `5px solid ${person.get('color')}` }}>
                    {person.get('name')}
                    <button onClick={(e) => people.remove(person)}>&times;</button>
                </li>})
            }
        </ul>
        <AddPerson people={people} />
    </section>
);

let AddPerson = ({ people }) => {
    let input;
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            if (input.value.trim()) {
                people.add({ name: input.value.trim() });
                input.value = "";
            }
        }}>
            <input placeholder="Fred" type="text" ref={(node) => input = node } />
            <input type="submit" value="Add" />
        </form>
    );
}
export default PeopleView;
