import _ from 'underscore';
import React from 'react';

let RepertoireView = ({ repertoire }) => {
    return (
        <section>
            { repertoire.people.map((person) => <RepertoireList key={person.cid} meals={repertoire} person={person} />) }
        </section>
    );
};

let RepertoireList = ({ meals, person }) => {
    let nameInput, portionsInput;
    let onDragStartFn = function(meal) {
        return (evt) => {
            evt.dataTransfer.setData("text/plain", JSON.stringify(meal.toJSON()));
            evt.dataTransfer.effectAllowed = "copy";
        }
    }
    let removeFn = function(meal) {
        return (evt) => {
            evt.preventDefault();
            meals.remove(meal);
        }
    }

    return (
        <div className="person">
            <h3>{person.get('name')}</h3>
            <RepertoireForm meals={meals} person={person} />
            <ul>
                {meals.where({ person }).map((meal) => {
                    return (
                        <li className="meal"
                        key={meal.cid}
                        draggable="true"
                        style={{ borderLeft: `10px solid ${person.get('color')}` }}
                        onDragStart={onDragStartFn(meal)}>
                            {meal.get('name')}
                            <button className="remove" onClick={removeFn(meal)}>&times;</button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

let RepertoireForm = ({ meals, person }) => {
    let nameInput, portionsInput;
    let onSubmitFn = function(evt) {
        evt.preventDefault();
        if (nameInput.value.trim()) {
            meals.add({ person, name: nameInput.value.trim(), portions: portionsInput.value.trim() || 1 });
            nameInput.value = "";
            portionsInput.value = 1;
        }
    };

    return (
        <form onSubmit={onSubmitFn}>
            <div>
                <label htmlFor="name">Recipe</label>
                <input placeholder="Soup" name="name" type="text" ref={(node) => nameInput = node } />
            </div>
            <input type="submit" value="Add" />
        </form>
    );
};

export default RepertoireView;
