import dayjs from 'dayjs';
import _ from 'underscore';
import { Collection, Model } from 'backbone';

// Represents a person.
export let Person = Model.extend({
    initialize: function() {
        if (!this.get('color')) {
            this.set({ color: "#" + _.times(3, (n) => _.random(1, 15).toString(16)).join("") });
        }
    },
});

export let People = Collection.extend({
    model: Person,
    save: function() {
        if (localStorage) {
            localStorage.setItem('people', JSON.stringify(this.toJSON()));
        }
    },

    fetch: function(options) {
        if (localStorage) {
            this.add(JSON.parse(localStorage.getItem('people')));
        }
    }
});

// Represents a meal - something a particular person can cook.
export let Meal = Model.extend({
    parse: function(response, options) {
        // Backbone objects have a toJSON method; object literals don't.
        if (!response.person.toJSON) {
            response.person = new Person(response.person);
        }
        return response;
    }
});
export let Meals = Collection.extend({
    model: Meal,

    initialize: function(models, options) {
        this.people = options.people;
    },

    parse: function(response, options) {
        return _.each(response, (meal) => meal.person = this.people.find(meal.person));
    },

    save: function() {
        if (localStorage) {
            localStorage.setItem('meals', JSON.stringify(this.toJSON()));
        }
    },

    fetch: function(options) {
        if (localStorage) {
            this.add(JSON.parse(localStorage.getItem('meals')), { parse: true });
        }
    }
});

// Represents a kind of meal. Breakfast, lunch, dinner, that kind of thing.
export let MealType = Model.extend({});
export let MealTypes = Collection.extend({
    model: MealType,

    save: function() {
        if (localStorage) {
            localStorage.setItem('mealTypes', JSON.stringify(this.toJSON()));
        }
    },

    fetch: function(options) {
        if (localStorage) {
            this.add(JSON.parse(localStorage.getItem('mealTypes')));
        }
    }
});


// Represents a particular "booking" on the calendar.
export let MealBooking = Model.extend({});
export let MealBookings = Collection.extend({
    model: MealBooking,

    initialize: function(models, options) {
        this.people = options.people;
        this.mealTypes = options.mealTypes;

        this.people.on('add', (model) => this._addPerson(model));
        this.people.on('remove', (model) => this._removePerson(model));
    },

    parse: function(response, options) {
        return _.each(response, (booking) => {
            booking.date = dayjs(booking.date);
            booking.person = this.people.find(booking.person);
            booking.mealType = this.mealTypes.find(booking.mealType);
            if (booking.meal) {
                booking.meal = new Meal(booking.meal, { parse: true });
            }
        });
    },

    save: function() {
        if (localStorage) {
            localStorage.setItem('mealBookings', JSON.stringify(this.toJSON()));
        }
    },

    fetch: function() {
        if (localStorage) {
            this.add(JSON.parse(localStorage.getItem('mealBookings')), { parse: true });
        }
        console.log(this);
    },

    // Create booking slots for this person.
    _addPerson: function(person) {
        this.getDates().map((date) => this.mealTypes.map((mealType) => this.add({ date, mealType, person })));
    },

    _removePerson: function(person) {
        let relevantBookings = this.where({ person });
        this.remove(relevantBookings);
    },

    getDates: function() {
        return _.uniq(this.pluck('date'), false, (dayjs) => dayjs.unix());
    },

    addDays: function(numDays) {
        let lastDay = this.lastDay();
        _.times(numDays, (n) => {
            let newDay = lastDay.clone().add(n + 1, 'days').startOf('day');
            this.mealTypes.map((mealType) => this.people.map((person) => this.add({ date: newDay, mealType, person })));
        });
    },

    lastDay: function() {
        if (this.isEmpty()) {
            return dayjs();
        } else {
            return this.max((booking) => booking.get('date').unix()).get('date');
        }
    }
});
