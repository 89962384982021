import _ from 'underscore';
import { People, MealTypes, Meals, MealBookings } from './entities';
import MealTypeView from './meal_type_view';
import MealTable from './meal_table_view';
import RepertoireView from './repertoire_view';
import PeopleView from './people_view';
import React from 'react';
import ReactDOM from 'react-dom';

// Load data from local storage, or use some defaults.
let people = new People();
people.fetch();
if (people.isEmpty()) {
    people.add([{ name: "Alex" }, { name: "Katrina" }]);
}

let mealTypes = new MealTypes();
mealTypes.fetch();
if (mealTypes.isEmpty()) {
    mealTypes.add([{ name: "Lunch"}, { name: "Dinner" }]);
}

let repertoire = new Meals(null, { people });
repertoire.fetch();
if (repertoire.isEmpty()) {
    repertoire.add([
        { person: people.at(0), name: "Meat and two veg" },
        { person: people.at(0), name: "Lentils, greens, and sausages" },
        { person: people.at(1), name: "Red Thai curry" },
        { person: people.at(1), name: "Salad bowl" }
    ]);
}

let bookings = new MealBookings(null, { people, mealTypes });
bookings.fetch();
if (bookings.isEmpty()) {
    bookings.addDays(3);
}

// Functions for rendering various parts.
let renderRepertoire = () => ReactDOM.render(<RepertoireView repertoire={repertoire} />, document.getElementById('repertoire'));
let renderMealTypes = () => ReactDOM.render(<MealTypeView meals={mealTypes} />, document.getElementById('meals'));
let renderMealTable = () => ReactDOM.render(<MealTable days="3" people={people} meals={mealTypes} bookings={bookings} />, document.getElementById('table_container'));
let renderPeople = () => ReactDOM.render(<PeopleView people={people} />, document.getElementById('people'));

// Update views and save data when collection changes.
people.on('update', () => {
    renderPeople();
    renderRepertoire();
    renderMealTable();
    people.save();
});

mealTypes.on('update', () => {
    renderMealTable();
    renderMealTypes();
    mealTypes.save();
});

repertoire.on('update', () => {
    renderRepertoire();
    renderMealTable();
    repertoire.save();
});

bookings.on('update change', () => {
    renderMealTable();
    bookings.save();
});

// Draw everything for the first time.
renderPeople();
renderMealTable();
renderMealTypes();
renderRepertoire();
