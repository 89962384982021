import _ from 'underscore';
import React from 'react';
import { Meal } from './entities';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(AdvancedFormat);

/**
 * @param People people collection of people.
 * @param MealTypes meals collection of MealTypes (lunch, dinner, etc)
 * @param Bookings Slots that are available (or occupied) for meals.
 */
class MealTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { direction: "vertical" };
    }

    render() {
        let dates = this.props.bookings.getDates();
        let groupedMeals;
        if (this._isVertical()) {
            groupedMeals = this.props.bookings.groupBy((booking) => booking.get('date').unix());
        } else {
            groupedMeals = this.props.bookings.groupBy((booking) => booking.get('person').cid);
        }

        return (
            <main>
                <table className={this.state.direction}>
                    {this._isVertical()
                            ? <VerticalHeader flipFlopFn={this.changeDirection.bind(this)} people={this.props.people} />
                            : <HorizontalHeader flipFlopFn={this.changeDirection.bind(this)} meals={this.props.meals} dates={dates} />
                    }
                    <tbody>
                        {Object.values(groupedMeals).map((bookings) => {
                            if (this._isVertical()) {
                                return <VerticalRow key={bookings[0].get('date')} bookings={bookings} />
                            } else {
                                return <HorizontalRow key={bookings[0].get('person').cid} bookings={bookings} />
                            }
                        })}
                    </tbody>
                </table>
                <button onClick={() => this.props.bookings.addDays(1)}>+</button>
            </main>
        );
                    }

    changeDirection() {
        if ("vertical" == this.state.direction) {
            this.setState({ direction: "horizontal" });
        } else {
            this.setState({ direction: "vertical" });
        }
    }

    _isVertical() {
        return "vertical" == this.state.direction;
    }

}


let VerticalHeader = ({ flipFlopFn, people }) => (
            <thead>
                <tr>
                    <th colSpan="2" className="invisible"><button onClick={flipFlopFn}>&#10530;</button></th>
                    {people.map((person) => <th key={person.cid}>{person.get('name')}</th>)}
                </tr>
            </thead>
);

let HorizontalHeader = ({ dates, meals, flipFlopFn }) => {
    return (
        <thead>
            <tr><th rowSpan="2" className="invisible"><button onClick={flipFlopFn}>&#10530;</button></th>
                {dates.map((date) => <th key={date.toString()} colSpan={meals.length}>{date.format('dddd Do')}</th>)}
            </tr>
            <tr>{dates.map((date) => meals.map((meal) => <th key={meal.cid}>{meal.get('name')}</th>))}</tr>

        </thead>
    );
};

let VerticalRow = ({ bookings }) => {
    let date = bookings[0].get('date');
    let mealTypes = _.uniq(bookings.map((booking) => booking.get('mealType')));

    return mealTypes.map((mealType, i) => {
        return (
            <tr key={mealType.cid} className={0 == i ? "newDay" : undefined}>
                { 0 == i && <td className="header" rowSpan={mealTypes.length}>{date.format('dddd Do')}</td> }
                <td className="header">{mealType.get('name')}</td>
                {_.select(bookings, (booking) => booking.get('mealType') == mealType).map((booking) => <MealCell key={booking.cid} booking={booking} />)}
            </tr>
        )
    });
}


let HorizontalRow = ({ bookings }) => (
    <tr>
        <td className="header">{bookings[0].get('person').get('name')}</td>
        {bookings.map((booking) => <MealCell key={booking.cid} booking={booking} />)}
    </tr>
);

let MealCell = ({ booking }) => {
    if (booking.get('meal')) {
        return (
            <td className="booked"
                draggable="true"
                key={booking.cid}
                onDragStart={(evt) => {
                    evt.dataTransfer.setData("text/plain", JSON.stringify(booking.get('meal').toJSON()));
                    evt.dataTransfer.effectAllowed = "copy";
                }}>
                <span className="swatch"
                style={{ backgroundColor: `${booking.get('meal').get('person').get('color')}` }}
                title={booking.get('meal').get('person').get('name')}>&nbsp;</span>
                {booking.get('meal').get('name')}
                <button className="remove" onClick={() => booking.set('meal', null)}>&times;</button>
            </td>
        );
    } else {
        return (
            <td key={booking.cid}
                className="empty"
                onDrop={(evt) => { booking.set('meal', new Meal(JSON.parse(evt.dataTransfer.getData('text/plain')), { parse: true })); }}
                onDragOver={(evt) => { evt.preventDefault(); evt.dataTransfer.dropEffect = "copy" }}
                onDragStart={(evt) => { evt.preventDefault(); evt.dataTransfer.dropEffect = "copy" }}>
                    &nbsp;
        </td>
        );
    }
};

export default MealTable;
